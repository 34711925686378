import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <nav className="container flex flex-col items-center justify-between w-full z-10 top-0">
      <div className="flex flex-row items-center justify-between w-full z-10 flex-nowrap">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <a className="text-white no-underline hover:text-white hover:no-underline" href="/">
            <StaticImage
              src="../images/logo-dark.png"
              height={110}
              width={200}
              placeholder="blurred"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Brand Logo"
            />
          </a>
        </div>

        <div className="block lg:hidden">
          <button onClick={toggleMenu} className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white hover:bg-gray-300 mr-4">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>

        <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0" id="nav-content">
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#services">Services</Link>
            </li>
            <li className="mr-3">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#about">About</Link>
            </li>
            <li className="mr-3">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#gallery">Gallery</Link>
            </li>
            <li className="mr-3">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      { menuOpen &&
        <div className="flex flex-col bg-white w-full transition transition-opacity duration-1000 ease-in-out">
          <ul className="list-reset lg:flex justify-end flex-1 items-center">
            <li className="mr-3 w-full">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#services">Services</Link>
            </li>
            <li className="mr-3 w-full">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#about">About</Link>
            </li>
            <li className="mr-3 w-full">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#gallery">Gallery</Link>
            </li>
            <li className="mr-3 w-full">
              <Link className="inline-block font-bold text-gray-700 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" to="#contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      }
    </nav>
  );
}

export default Navbar