import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => (
  <footer className="bg-gray-100 flex flex-row justify-between items-center">
    <StaticImage
      src="../images/logo-dark.png"
      height={83}
      width={150}
      placeholder="blurred"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Brand Logo"
    />
    <div className="flex flex-col justify-center items-start m-4">
      <div className="flex flex-row items-center">
        <a
          href="mailto:heatherbraecontracting@gmail.com"
        >
          <img src="https://img.icons8.com/ios/32/000000/apple-mail.png"/>
        </a>
        <a
          href="https://www.facebook.com/heatherbraecontracting/"
        >
          <img src="https://img.icons8.com/ios/32/000000/facebook-new.png"/>
        </a>
        <a
          href="https://instagram.com/heatherbraecontracting"
        >
          <img src="https://img.icons8.com/ios/30/000000/instagram-new--v1.png"/>
        </a>
      </div>
      <p className="text-sm">© {new Date().getFullYear()} Heatherbrae Contracting</p>
    </div>
  </footer>
)

export default Footer